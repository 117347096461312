import { Component, OnInit } from '@angular/core';
import { VirtualTimeScheduler } from 'rxjs';
import { CommonService } from '../common-service/common.service';
import { ToastrCustomService } from '../common-service/toastr.service';
import { Router } from '@angular/router';
import * as moment from 'moment';


declare var NimbblCheckout: any;
declare global { interface Window { checkout: any; } }
@Component({
  selector: 'app-guest-info',
  templateUrl: './guest-info.component.html',
  styleUrls: ['./guest-info.component.css']
})
export class GuestInfoComponent implements OnInit {
  bookedRoom: any = {};
  totalFare: number = 0
  totalTax: number = 0
  guestName: any
  mobileNo: number
  email: any
  prefix: string = "Mr.";
  promo: any
  submitClick : boolean =false
  terms : any
  appliedCode: boolean= false;
  totalCharge: number=0;
  voucherList : Array<any>= []
  discount : number=0;
  fareAfterDiscount: number;
  taxAfterDiscount: number;
  conversionRate: number=0;
  currencySymbol: any="";
  address: any;
  couponAmount:number=0;
  IsPaymentGatway: boolean = false;
  constructor(
    public commonService: CommonService,
    public toastrService : ToastrCustomService,
    private router: Router
    ) {
      this.getVoucherList()
     }

  ngOnInit(): void {
    this.commonService.hotelInfoUpdated.subscribe(() => {
      this.prefix =this.commonService.hotelDetails.NameTitleList[0].Id;
    })
    this.getBookingData()
    this.calculateTotalTaxAndCharge()
  }


  getBookingData(){

    this.bookedRoom = this.commonService.bookedRoom
    this.commonService.searchStatus().subscribe((obj)=>{
      this.currencySymbol = obj.symbol
      this.conversionRate = +obj.rate?+obj.rate:1
    })
  }

  calculateTotalTaxAndCharge(discount?){
    if(this.bookedRoom && this.bookedRoom.length > 0){
      this.bookedRoom.forEach(el => {
        let fare = 0
        let tax = 0
        el.Price[0].forEach(elem => {
          fare = fare + elem.RoomRent
          tax = tax + elem.TaxAmount
        })
        el['Fare'] = +(fare.toFixed(2))
        el['Tax'] = +(tax.toFixed(2))
        if(discount===null ||discount=== undefined ){
        this.totalFare = +((this.totalFare + (fare * (el.NoOfRooms))).toFixed(2))
        }
        if(discount==='afterDiscount'){
        this.fareAfterDiscount = +((this.fareAfterDiscount + (fare * (el.NoOfRooms))).toFixed(2))
        this.taxAfterDiscount = +((this.taxAfterDiscount + (tax * (el.NoOfRooms))).toFixed(2))
        }
      })
      if(discount===null ||discount=== undefined ){
      this.totalTax = +((this.commonService.totalRoomRent - this.totalFare).toFixed(2))
      this.totalCharge = +((this.commonService.totalRoomRent + this.commonService.totalDealCharge).toFixed(2))
      }
      if(discount==='afterDiscount'){
      this.discount = +(((this.totalTax - this.taxAfterDiscount) + (this.totalFare- this.fareAfterDiscount)).toFixed(2))
      this.totalCharge = +((this.taxAfterDiscount + this.fareAfterDiscount + this.commonService.totalDealCharge).toFixed(2))
      }
    }
  }

  getVoucherList(){
    this.commonService.getVoucherList().subscribe((res)=>{
      if(res.Status === 0){
        this.voucherList = res.Data
      }
    })
  }
  applyPromo() {
    if(this.promo){
      this.commonService.validateVoucher(this.promo).subscribe((res)=>{
        if(res.Data.TypeId === -1){
          this.toastrService.showError('','Please Enter Valid Voucher Code')
          this.appliedCode = false
        }
        else{
          this.toastrService.showSuccess('','Voucher Applied Succesfully')
          this.appliedCode = true
          this.couponAmount = res.Data.Amount
          if(res.Data.TypeId===0){
            this.calculateDiscountedCharges(this.couponAmount,'Amount')
          }
          if(res.Data.TypeId===1){
            this.calculateDiscountedCharges(this.couponAmount,'percent')
          }
        }
      })
    }
  }
  calculateDiscountedCharges(value,type){
    this.fareAfterDiscount=0
    this.taxAfterDiscount=0
    this.discount=0
    let appliedTaxRange={}
    if(this.bookedRoom && this.bookedRoom.length){
      this.bookedRoom.forEach(el => {
        el.Price[0].forEach(elem => {
          if(el.TaxOnMrp === 1 ){
            appliedTaxRange = this.commonService.taxRange.filter((d)=>(d.AmountFrom <= elem.RoomRent && d.AmountTo >= elem.RoomRent))
          }
          if(type ==='Amount'){
          elem.RoomRent =   elem.RoomRent - value
          }
          else{
            elem.RoomRent = +((elem.RoomRent - (elem.RoomRent*value/100)).toFixed(2))
          }
          if(el.TaxOnMrp === 0){
          appliedTaxRange = this.commonService.taxRange.filter((d)=>(d.AmountFrom <= elem.RoomRent && d.AmountTo >= elem.RoomRent))
          }
          elem.TaxAmount = +((elem.RoomRent*appliedTaxRange[0].TotalTax/100).toFixed(2))
        })
      })
    }
    this.calculateTotalTaxAndCharge('afterDiscount')
}

submit() {
  this.submitClick = true
  if(this.prefix && this.terms && this.email && this.guestName && this.mobileNo ){
    let roomData = this.getPostParams(this.bookedRoom)
    let body = {
      Id: 0,
      PartyId: 0,
      NameTitleId:this.prefix ,
      PartyName:this.guestName,
      Gender: "",
      DOB: "",
      Email: this.email,
      PhoneNo: "",
      MobileNo: this.mobileNo,
      Address: "",
      ReservationType: 2,
      MarketplaceTypeId: 0,
      MarketplaceId: 2,
      CompanyId: 0,
      PartyCompanyName: "",
      PartyGstNo: "",
      VoucherNo: "",
      ArivalCityId: 0,
      DepartureCityId: 0,
      FromBookingEngine:1,
      ResvFromDate: this.commonService.arrival,
      ResvToDate: this.commonService.departure,
      GatewayAmount: this.totalCharge,
      CashAmount: "0",
      PaymentGatewayId: "5",
      CouponCode: this.promo?this.promo:"",
      ReservationDetailsPms: roomData,
      ReservationServiceDetlist: this.commonService.selectedDeals
    }
    this.commonService.GetMasterSettingList().subscribe({next:(res) => {
      if(res.Status === 0){
        res.Data.forEach(element => {
          if (element.settingname == "PaymentGatewayCompulsory") {            
          this.IsPaymentGatway =element.value == 1 ? true : false;
          if(this.IsPaymentGatway ==true)
          {
            this.commonService.getPaymentGatewayType(this.commonService.HotelId)
            .subscribe((res) => {
              if(res.Status==0 &&res.Data && res.Data!=null)
                {  
                  this.commonService.postBookingInfo(body).subscribe({next:(res) => {
                    if(res.Status === 0){
                      // console.log('aa',res.Data)
                      // console.log('aa11',res.Data[0].ID)
                        let sum = this.totalCharge
                        let orderId = res.Data.Id;
                        let RedirectUrl = res.Data.RedirectUrl;
                        const dataToPass = {sum,orderId,RedirectUrl}
                       this.getPaymentGatewayType(dataToPass)
                      } else{
                        this.toastrService.showError('',res.Message)
                    }
                  }
                })     
                         }
              else{
                this.toastrService.showError('',"Payment Gateway Not implemented ,kindly contact to admin!!")
                this.router.navigate(['/home']);
              }
            }
            )

          }
          else{

            this.commonService.postBookingInfo(body).subscribe({next:(res) => {
              if(res.Status === 0){
                this.toastrService.showSuccess('','Booking Save Sucessfully')
               
                } else{
                  this.toastrService.showError('',res.Message)
              }
            }
          })  
          }
        }
      })
    }
  }})
   
    
  }
}

  getPaymentGatewayType(dataReceived){  
    
    this.commonService.getPaymentGatewayType(this.commonService.HotelId).subscribe((res) => {
      if(res.Status==0 &&res.Data && res.Data!=null){  
      if(res.Status==0 &&res.Data.MarketPlaceId && res.Data.MarketPlaceId>0){  
        
        if(res.Data.MarketPlaceId == 11){        
          const dataToPass = {
            marketPlaceId: res.Data.MarketPlaceId,
            orderId: dataReceived.orderId
          }          
          this.getNibbleBookingToken(dataToPass);
        } 
        else if(res.Data.MarketPlaceId == 5){
          let token= sessionStorage.getItem("Token")
          this.router.navigate(['/home']);
          window.open ('https://backoffice.tpms24x7.com/_gpay.aspx?Token='+token+'&GatewayId=5&Amount='+dataReceived.sum+'&OrderId='+dataReceived.orderId+'&HotelId='+this.commonService.HotelId)
          // console.warn('https://backoffice.tb24x7.com/_gpay.aspx?Token='+token+'&GatewayId=5&Amount='+dataReceived.sum+'&OrderId='+dataReceived.orderId+'&HotelId='+this.commonService.HotelId,'_blank')
          this.toastrService.showSuccess('',"saved Successfully")
        }
        else if(res.Data.MarketPlaceId == 20){
          let token= sessionStorage.getItem("Token")
          this.router.navigate(['/home']);
        //console.warn('https://payu.tb24x7.com/default.aspx?Token='+token+'&OrderId='+dataReceived.orderId,'_blank');
          window.open ('https://Payu.tpms24x7.com/default.aspx?Token='+token+'&OrderId='+dataReceived.orderId)
          this.toastrService.showSuccess('',"saved Successfully")
        }
        else if(res.Data.MarketPlaceId == 23){
          let token= sessionStorage.getItem("Token")
          this.router.navigate(['/home']);
          //console.warn('https://payu.tpms24x7.com/icici.aspx?Token='+token+'&OrderId='+dataReceived.orderId,'_blank');
         window.open ('https://payu.tpms24x7.com/icici.aspx?Token='+token+'&OrderId='+dataReceived.orderId)
          this.toastrService.showSuccess('',"saved Successfully")
        }
        else if(res.Data.MarketPlaceId == 26){
          let token= sessionStorage.getItem("Token")
          this.router.navigate(['/home']);
         // console.warn('http://localhost:4188/ccave.aspx?Token='+token+'&OrderId='+dataReceived.orderId,'_blank');
         window.open ('https://payu.tpms24x7.com/ccave.aspx?Token='+token+'&OrderId='+dataReceived.orderId)
         //window.open ('http://localhost:4188/ccave.aspx?Token='+token+'&OrderId='+dataReceived.orderId,'_blank')
          this.toastrService.showSuccess('',"saved Successfully")
        }
        else if(res.Data.MarketPlaceId == 30){
          let token= sessionStorage.getItem("Token")
          this.router.navigate(['/home']);
         // console.warn('http://localhost:4188/ccave.aspx?Token='+token+'&OrderId='+dataReceived.orderId,'_blank');
         window.open ('https://payu.tpms24x7.com/ccave.aspx?Token='+token+'&OrderId='+dataReceived.orderId)
         //window.open ('http://localhost:4188/ccave.aspx?Token='+token+'&OrderId='+dataReceived.orderId,'_blank')
          this.toastrService.showSuccess('',"saved Successfully")
        }
        else if(res.Data.MarketPlaceId == 31){
          if(dataReceived.orderId>0 && dataReceived.RedirectUrl){
            window.location.href=dataReceived.RedirectUrl;
        }
      }
      else if(res.Data.MarketPlaceId == 33){
        let token= sessionStorage.getItem("Token")
        this.router.navigate(['/home']);
         //console.warn('http://localhost:4188/airPay.aspx?Token='+token+'&OrderId='+dataReceived.orderId);
       window.open ('https://payu.tpms24x7.com/airPay.aspx?Token='+token+'&OrderId='+dataReceived.orderId)
         //window.open ('http://localhost:4188/airPay.aspx?Token='+token+'&OrderId='+dataReceived.orderId,'_blank')
         this.toastrService.showSuccess('',"saved Successfully")
      }
        
        else
        {         
         this.router.navigate(['/confirmation']);
        }
      }
      else{   
       
        this.router.navigate(['/confirmation']);
      }
    }
    else{   
    
      this.toastrService.showSuccess('',"saved Successfully")
      this.router.navigate(['/home']);
    }
    }
    
    )
  }

  getNibbleBookingToken(dataReceived){    
    this.commonService.getNibbleBookingToken(dataReceived.marketPlaceId, dataReceived.orderId).subscribe((res) => {
      if(res.Status===0){
        const dataToPass = {...dataReceived}
        dataToPass['nimbbleOrderId'] = res.Data.order_id
        this.checkOutNimbbalPaymentGateway(dataToPass)
      }
    })
  }

  checkOutNimbbalPaymentGateway(dataReceived){   
    this.commonService.getNimbbleAccessKey().subscribe((accessKeyRes) => {
      if(accessKeyRes && accessKeyRes.Data && accessKeyRes.Data.length > 0 ){
        let access_keyData = accessKeyRes.Data.find(item => item.Parameter == "access_key");
        let nimbbleAccessKey = access_keyData ? access_keyData.ParameterValue : null
        const gatewayOptions = {
          "access_key": nimbbleAccessKey, // Enter the Key ID generated from the Dashboard
          "order_id": dataReceived['nimbbleOrderId'], // Enter the order_id from the create-order api which is generated through nimbble order creation api
          "callback_handler": (response) => {
            this.postNibbleCheckoutStatus(dataReceived, response);
            if (response.status == "failed") {
              this.toastrService.showError('', "Payment has been failed");
              let param = {
                MarketPlaceId: dataReceived.marketPlaceId,
                OrderId: dataReceived.orderId,
                OrderStatusB: response.status,
                Trackingid: response.nimbbl_transaction_id,
                gatwayname:""
              }
            }
            if (response.status == "success") {
           
              this.toastrService.showSuccess('', "Order placed successfully!!!");
              let param = {
                MarketPlaceId: dataReceived.marketPlaceId,
                OrderId: dataReceived.orderId,
                OrderStatusB: response.status,
                Trackingid: response.nimbbl_transaction_id,
                gatwayname:""
              }
              this.router.navigate(['/confirmation'], { queryParams: param});
            }
          },
          "custom": {},
      };
      window['checkout'] = new NimbblCheckout(gatewayOptions);
      window['checkout'].open(dataReceived['nimbbleOrderId']);
    }
    })

  }

  postNibbleCheckoutStatus(data, nimbbleRes){
    let payload = {
      MarketPlaceId: data.marketPlaceId,
      OrderId: data.orderId,
      OrderStatusB: nimbbleRes.status,
      Trackingid: nimbbleRes.nimbbl_transaction_id,
      TxnAmount:data.Amount,
      TxnId:nimbbleRes.nimbbl_order_id,
      CHECKSUMHASH:"",
      gatwayname:"",     
    }    
    this.commonService.postNibbleCheckoutStatus1(payload).subscribe((res) => {  
    })
  }
  getDaysFromDates(start, end): number {
    var date1 = moment(start);
    var date2 = moment(end);
    var days = date2.diff(date1, 'days');
    return days;
  }

  getPostParams(data) {
    let obj = []
    let dataToPost = {}
    if(data && data.length){
      data.forEach((d, i) => {
        // d.occupancy.forEach((el, j) => {
        //   d.Price[0].forEach((e, k) => {
            dataToPost = {
              ResvDate:this.commonService.arrival,
              ResvTime: "00:00:00",
              CheckOutDate:this.commonService.departure,
              checkouttime: "00:00:00",
              Days:  this.getDaysFromDates(this.commonService.arrival,this.commonService.departure) || 1,
              RoomNos: 1,
              RoomCategoryId: d.RoomId,
              RoomTypeId: d.RoomTypeIdRoom,
              PlanTypeId: d.Id,
              GuestTypeId: 287,
              TaxTypeId: 0,
              Male: d.occupancy.adults,
              FeMale: 0,
              Child: d.occupancy.childs,
              Age: d.occupancy.age,
              RoomRent: d.TotalRent,
              DiscountPer: 0,
              DiscountAmount: 0,
            }
            obj.push(dataToPost)
          });

      //   })
      // })
    }
    return obj
  }

}
