import { ToastrCustomService } from './../common-service/toastr.service';
import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { CommonService } from '../common-service/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '../common-service/token.service';
import { NgForm } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select'
declare var $: any
import * as _ from 'lodash';
import * as moment from 'moment';
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
	
       PartyName :string;
        Adults:number;
         Child :number;
        Email :string;
        Phone :string;
		Rooms :number;
        Message :string;
        FromDate:any=moment().add('days',1).format('YYYY-MM-DD');
       ToDate :any= moment().format('YYYY-MM-DD');
	submitClick : boolean =false
	noOfRooms: number;
	noOfRoomsPlan: number;
	bookingArray: any = [];
	roomsData: Array<any>;
	bookedRoomsData: any = [];
	RoomInfo: any;
	modelToShow: boolean = false;
	bookedRoom: number = 0;
	totalbookedRoom: number = 0;
	arrival: any;
	departure: any;
	occupancy: any = [];
	TotalBill: number = 0;
	adults: number;
	childs: number;
	dealsData: any;
	today: any=moment().format('YYYY-MM-DD')
	nights: number;
	pricePopupToShow: boolean = false;
	priceData: any;
	currencyId: number;
	currencySymbol: any
	conversionRate: number = 1;
	@ViewChild('searchForm') searchFormControl: NgForm
	fromAmountSearch: any
	toAmountSearch: any
	planArray: any = [{ Id: 0, Name: 'Select Plan' }];
	searchedNoOfRooms: number
	categoryArray: any = [{ Id: 0, Name: 'Select Category' }];
	selectedCategory: any;
	selectedPlan: any;
	bookingType: any
	constructor(
		public commonService: CommonService,
		public toastrService: ToastrCustomService,
		private router: Router,
		public activatedRoute: ActivatedRoute,
		public tokenService: TokenService
	) {
		//this.today = moment().format('YYYY-MM-DD');
		if (_.isEmpty(this.commonService.arrival)) {
			this.arrival = moment().format('YYYY-MM-DD')
			this.departure = moment().add('days',1).format('YYYY-MM-DD')
			//this.departureDate()
		} else {
			var datearray = this.commonService.arrival.split("-");
			var newdate = datearray[0] + '-' + datearray[1] + '-' + datearray[2];
			this.arrival = newdate
			var datearray1 = this.commonService.departure.split("-");
			var newdate1 = datearray1[0] + '-' + datearray1[1] + '-' + datearray1[2];
			this.departure = newdate1
			//this.getRoomInfo()
		}
		this.commonService.searchStatus().subscribe((obj) => {
			this.currencyId = obj.currencyId
			this.currencySymbol = obj.symbol
			this.conversionRate = +obj.rate ? +obj.rate : 1
		})

	}
	getNext(date){
		return moment(date,'YYYY-MM-DD').add('days',1).format('YYYY-MM-DD')
	}
	ngOnInit() {
		this.getRoomInfo()
		this.commonService.homeUrl = window.location.href
		setTimeout(() => {
			this.getRoomPlanArray()
		}, 2000);
		this.bookingType = 'OverNight Stay'
		
	}
	clickNextSlider(){
		setTimeout(() => {
		  document.getElementById('nextSlider').click();
		},500);
	}
	
	submiten() {
		this.submitClick = true
		if(this.PartyName && this.Phone && this.Email && this.Adults && this.Child&& this.Rooms ){			
			let body = {
			  Id: 0,
			  PartyName: this.PartyName,
			  Email:this.Email ,
			  Phone:this.Phone,
			  Adults: this.Adults,
			  Child: this.Child,
			  Rooms: this.Rooms,
			  Message: this.Message,
			  FromDate: this.FromDate,
			  ToDate:this.ToDate
			}
		   
			this.commonService.Bookingenquiry(body).subscribe({next:(res) => {
			  if(res.Status === 0){
				this.toastrService.showSuccess('',res.Message)
				} else{
				  this.toastrService.showError('',res.Message)
			  }
			}})
		  }
		  else
		  {
			alert("Select requird filed..")
		  }
		
	}

	@ViewChildren("planDetail") planDetail;
	changeBookingType(type) {
		this.bookingType = type
		this.roomsData = []
		this.bookedRoomsData = []
		if (type === 'Day Room') {
			this.departure = this.arrival
		}
		else {
			this.departure = this.getNextDate(this.arrival)
		}
	}
	getRoomPlanArray() {
		this.roomsData.forEach((data, index) => {
			let obj = {
				Id: data.RoomId,
				Name: data.RoomName
			}
			this.categoryArray.push(obj)
			if (data.RoomPlan) {
				data.RoomPlan.forEach(el => {
					let unique = []
					if (this.planArray.length > 0) {
						unique = this.planArray.filter((d) => d.Name === el.Name)
					}
					if (unique.length === 0) {
						let obj = {
							Id: el.Id,
							Name: el.Name
						}
						this.planArray.push(obj)
					}
				})
			}
		})
	}
	
	search() {
		this.getRoomInfo();
	}

	@ViewChild('category_select') categoryselectController: NgSelectComponent

	reset() {
		this.selectedCategory = null
		this.selectedPlan = 0
		this.searchedNoOfRooms = 0
		this.fromAmountSearch = ''
		this.toAmountSearch = ''
	}
	departureDate1(evt?) {	
		this.FromDate = moment(this.ToDate,'YYYY-MM-DD').add('days',1).format('YYYY-MM-DD')
		
	}
	departureDate(evt?) {
		
		this.departure = moment(this.arrival,'YYYY-MM-DD').add('days',1).format('YYYY-MM-DD')
		this.roomsData = []
		this.bookedRoomsData = []
	}

	getTomorrow() {
		return this.getNextDate(this.arrival)
	}
	getPreviousDate() {
		var maxDate = new Date(+new Date(this.departure) - 86400000);
		return maxDate.toLocaleDateString('en-CA');
	}
	getNextDate(date) {
		var nextDate = new Date(+new Date(date) + 86400000);
		return nextDate.toLocaleDateString('en-CA');
	}
	noOfNight() {
		var date1 = new Date(this.arrival);
		var date2 = new Date(this.departure);
		this.nights = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
	}
	transformCount(length: number, offset: number = 0): number[] {
		if (!length) {
			return [];
		}
		const array = [];
		for (let n = 0; n < length; ++n) {
			array.push(offset + n);
		}
		return array;
	}

	adultSelect(evt, category, plan, occupancyindex) {
		let room = this.bookedRoomsData.filter(d => d.RoomId === category.RoomId && d.RoomPlan === plan.Name);
		room[0].occupancy[occupancyindex].adults = evt.target.value;
	}

	childSelect(evt, category, plan, occupancyindex) {
		let room = this.bookedRoomsData.filter(d => d.RoomId === category.RoomId && d.RoomPlan === plan.Name);
		room[0].occupancy[occupancyindex].childs = +evt.target.value;
	}

	ageSelect(evt, category, plan, occupancyindex, ageIndex) {
		let room = this.bookedRoomsData.filter(d => d.RoomId === category.RoomId && d.RoomPlan === plan.Name);
		room[0].occupancy[occupancyindex].age[ageIndex] = +evt.target.value;
	}

	addOccupancy(parent,Adult) {
		let occupancy = {
			adults: Adult,
			childs: 0,
			age: []
		};
		return occupancy;
	}

	addPrice(parent) {
		let priceArray = [];
		parent.forEach((d) => {
			let price = {
				BookingDate: d.BookingDate,
				NextDate: this.getNextDate(d.BookingDate),
				RoomRent: d.RoomPrice,
				TaxAmount: d.TotalTaxAmount,
				TaxRate: d.TaxDetails[0].TotalTax,
			};
			priceArray.push(price);
		});
		return priceArray;
	}

	checkAvailablity(categoryId, roomPlanId, op,selectedRoom=null,j=null,k=null) {
		let roomCategoryIndex, roomPlanIndex;
		roomCategoryIndex = this.roomsData.findIndex(x => x.RoomId == categoryId);
		if (roomCategoryIndex >= 0) {
			roomPlanIndex = this.roomsData[roomCategoryIndex].RoomPlan.findIndex(x => x.Id == roomPlanId);
			if (roomPlanIndex >= 0) {
				if (op == 'remove') {
					this.roomsData[roomCategoryIndex].AvlQty = this.roomsData[roomCategoryIndex].AvlQty - 1;
					this.roomsData[roomCategoryIndex].RoomPlan[j].roomPriceListToShowInFront[k]['bookedQty'] = this.roomsData[roomCategoryIndex].RoomPlan[j].roomPriceListToShowInFront[k]['bookedQty'] ?
						(this.roomsData[roomCategoryIndex].RoomPlan[j].roomPriceListToShowInFront[k]['bookedQty'] + 1) : 1
				} else if (op == 'add') {
					this.roomsData[roomCategoryIndex].AvlQty = this.roomsData[roomCategoryIndex].AvlQty + 1;
					if (this.roomsData[roomCategoryIndex].RoomPlan[j].roomPriceListToShowInFront[k]['bookedQty']) {
						this.roomsData[roomCategoryIndex].RoomPlan[j].roomPriceListToShowInFront[k]['bookedQty'] = this.roomsData[roomCategoryIndex].RoomPlan[j].roomPriceListToShowInFront[k]['bookedQty'] - 1
					}
				}
			}
		}
	}
	addRoom(category, plan, selectedRoom,j=null,k=null) {
		let availableRoom = this.roomsData.filter(x => x.RoomId === category.RoomId);
		if (this.bookedRoomsData.length > 0) {
			let room = this.bookedRoomsData.filter(y => y.RoomId === category.RoomId && y.RoomPlan === plan.Name && y.RoomTypeIdRoom === selectedRoom.RoomTypeIdRoom);
			if (room.length > 0) {
				if (availableRoom[0].AvlQty > 0) {
					room[0].NoOfRooms = room[0].NoOfRooms + 1;
					room[0].occupancy.push(this.addOccupancy(category,selectedRoom.Adult));
					this.totalbookedRoom = this.totalbookedRoom + 1;
					this.checkAvailablity(category.RoomId, plan.Id, 'remove',selectedRoom,j,k);
				} else {
					alert('All Available room Selected');
				}
			} else if (availableRoom[0].AvlQty > 0) {
				this.addNewRoomToBooking(category, plan, selectedRoom,j,k)
			}
		} else if (availableRoom[0].AvlQty > 0) {
			this.addNewRoomToBooking(category, plan, selectedRoom,j,k)
		}
		this.getTotalBill()
	}

	addNewRoomToBooking(category, plan, selectedRoom,j,k) {
		let filteredRoomPriceList = plan.Dates.filter((t) => {
			return ((t.RoomTypeIdRoom === selectedRoom.RoomTypeIdRoom) && ((this.bookingType === "OverNight Stay" && t.BookingDate < this.departure) || (this.bookingType === "Day Room" && t.BookingDate > this.departure)))
		})
		let occupancy = [];
		let price = [];
		let totalRent = 0;
		let totalTax = 0;
		filteredRoomPriceList.forEach((k) => {
			totalRent += k.RoomPrice;
			totalTax += k.TotalTaxAmount;
		})
		price.push(this.addPrice(filteredRoomPriceList));
		occupancy.push(this.addOccupancy(category,selectedRoom.Adult));
		let obj = {
			RoomTypeId: category.RoomTypeId,
			RoomId: category.RoomId,
			Id: plan.Id,
			Category: category.RoomName,
			RoomPlan: plan.Name,
			RoomTypeName: selectedRoom.RoomTypeName,
			RoomTypeIdRoom: selectedRoom.RoomTypeIdRoom,
			Price: price,
			NoOfRooms: this.bookedRoom + 1,
			occupancy: occupancy,
			arrival: this.arrival,
			departure: this.departure,
			TaxOnMrp: category.RoomTaxonMRP,
			TotalRent: totalRent,
			TotalTax: totalTax
		};
		this.bookedRoomsData.push(obj);
		this.totalbookedRoom = this.totalbookedRoom + 1;
		this.checkAvailablity(category.RoomId, plan.Id, 'remove',selectedRoom,j,k);
	}

	removeRoom(category, plan, selectedRoom,j,k) {
		if (this.bookedRoomsData.length > 0) {
			let room = this.bookedRoomsData.filter((d) => {
				return (d.RoomId === category.RoomId && d.Id === plan.Id && d.RoomTypeIdRoom === selectedRoom.RoomTypeIdRoom)
			}
			);
			if (room[0].NoOfRooms > 1) {
				room[0].NoOfRooms = room[0].NoOfRooms - 1;
				room[0].occupancy.pop();
				this.totalbookedRoom = this.totalbookedRoom - 1;
				this.checkAvailablity(category.RoomId, plan.Id, 'add',selectedRoom,j,k);
			} else if ((room[0].NoOfRooms = 1)) {
				var index;
				for (var i in this.bookedRoomsData) {
					if (
						this.bookedRoomsData[i].RoomPlan == room[0].RoomPlan &&
						this.bookedRoomsData[i].RoomId == room[0].RoomId
					) {
						index = i;
						this.bookedRoomsData.splice(index, 1);
						this.totalbookedRoom = this.totalbookedRoom - 1;
						this.checkAvailablity(category.RoomId, plan.Id, 'add',selectedRoom,j,k);
					}
				}
			}
			this.getTotalBill()
		}
	}
	getTotalBill() {
		this.TotalBill = 0;
		this.bookedRoomsData.forEach((el) => {
			let bill = 0;
			let Roombill = 0;
			el.Price[0].forEach((elem) => {
				bill = bill + elem.RoomRent + elem.TaxAmount;
			});
			Roombill = el.NoOfRooms * bill;
			this.TotalBill = this.TotalBill + Roombill;
		});
	}
	deleteBookingRow(booking, k) {
		let roomCategoryIndex, roomPlanIndex;
		roomCategoryIndex = this.roomsData.findIndex(x => x.RoomId == booking.RoomId);
		if (roomCategoryIndex >= 0) {
			roomPlanIndex = this.roomsData[roomCategoryIndex].RoomPlan.findIndex(x => x.Id == booking.Id);
			if (roomPlanIndex >= 0) {
				this.roomsData[roomCategoryIndex].AvlQty = this.roomsData[roomCategoryIndex].AvlQty + this.roomsData[roomCategoryIndex].RoomPlan[roomPlanIndex]['bookedQty'];
				this.totalbookedRoom = this.totalbookedRoom - this.roomsData[roomCategoryIndex].RoomPlan[roomPlanIndex]['bookedQty'];
				this.roomsData[roomCategoryIndex].RoomPlan[roomPlanIndex]['bookedQty'] = 0
			}
		}
		this.bookedRoomsData.splice(k, 1);
		this.getTotalBill()
	}
	editRoom(booking, k) {
		let focusedElement
		for (var i in this.roomsData) {
			if (this.roomsData[i].RoomId == booking.RoomId) {
				for (var j in this.roomsData[i].RoomPlan) {
					if (this.roomsData[i].RoomPlan[j].Name == booking.RoomPlan) {
						focusedElement = this.planDetail._results[i].nativeElement.children[j];
						focusedElement.focus();
					}
				}
			}
		}

	}
	openPopup(room) {
		console.log('room',room)
		this.RoomInfo = {}
		this.modelToShow = true;
		this.RoomInfo = room;
	}
	pricePopup(booking) {
		this.priceData = {};
		this.pricePopupToShow = true;
		this.priceData = booking;
	}
	submit() {
		if (this.bookedRoomsData.length > 0) {
			this.commonService.bookedRoom = this.bookedRoomsData;
			this.commonService.totalRoomRent = this.TotalBill;
			this.commonService.bookingType = this.bookingType;
			this.commonService.nights = this.nights
			this.router.navigate(['billing-info']);
		}
	}
	categorySelect(evt) {
		this.selectedCategory = evt
	}
	planSelect(evt) {
		this.selectedPlan = evt
	}
	getRoomInfo() {
		this.noOfNight()
		this.bookedRoomsData = [];
		let searchParam = {
			arrival: this.arrival,
			departure: this.bookingType === 'Day Room' ? this.getNextDate(this.arrival) : this.departure,
			
			currencyId: this.currencyId ? this.currencyId : 0,
			RoomCategoryId: this.selectedCategory ? this.selectedCategory : 0,
			RoomPlanId: this.selectedPlan ? this.selectedPlan : 0,
			AmountFrom: this.fromAmountSearch ? this.fromAmountSearch : '',
			AmountTo: this.toAmountSearch ? this.toAmountSearch : ''
		}
		console.log('departure',searchParam);
		this.commonService.getRoomInfo(searchParam).subscribe((res) => {
			if (res.Status === 0) {
				this.roomsData = res.Data.RoomCategoryList;
				this.roomsData.forEach((data, index) => {
					if (data.RoomPlan) {
						data.RoomPlan.forEach(el => {
							let TotalRent = 0
							let TotalTax = 0
							let firstDate = null;
							if (el.Dates.length > 1) {
								firstDate = el.Dates[0]["BookingDate"]
							}
							el['roomPriceListToShowInFront'] = el.Dates.filter(r => r.BookingDate == firstDate);
							el.Dates.forEach((ele) => {
								TotalRent += ele.RoomPrice
								TotalTax += ele.TotalTaxAmount
							})
							el['TotalRent'] = TotalRent
							el['TotalTax'] = TotalTax
						});
					}
				})
				$('#filter-opt-action').collapse('hide')
				this.commonService.dealsData = res.Data.RoomCategoryList[0].DealList
				this.commonService.taxRange = res.Data.RoomCategoryList[0].TaxOnRange
			}
		});
	}

	checkIsRoomAddedToBookedRoom(roomCategory, roomPlan, roomItem): boolean {
		return this.bookedRoomsData.some(item => item.RoomId === roomCategory.RoomId && item.Id === roomPlan.Id && item.RoomTypeIdRoom === roomItem.RoomTypeIdRoom);
	}
}
export interface room {
	category: any;
	noOfRoom: any;
	personInRoom: {};
	offer: any;
}
